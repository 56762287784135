import Food from '../../shared/assets/categories/food.png';
import Toys from '../../shared/assets/categories/toys.png';
import Accessories from '../../shared/assets/categories/accessories.png';
import Bedding from '../../shared/assets/categories/bedding.png';
import Hygiene from '../../shared/assets/categories/hygiene.png';
import All from '../../shared/assets/categories/all.png';
import styles from './categories.module.css';
import {Link} from "react-router-dom";

const Categories = () => {
    const categories = [
        {
            display: "All",
            img: All
        },
        {
            display: "Food",
            img: Food
        },
        {
            display: "Toys",
            img: Toys
        },
        {
            display: "Accessories",
            img: Accessories
        },
        {
            display: "Hygiene",
            img: Hygiene
        },
        {
            display: "Bedding",
            img: Bedding
        },
    ]

    return (
        <div className={styles['categories']}>
            <div className={`${styles['categories-scroll']}`}>
                {categories.map((item, i) =>
                    <Link key={i} to={`/products?category=${item.display}`} className={styles['category']}>
                        <div>{item.display}</div>
                        <img src={item.img} alt={item.display}/>
                    </Link>
                )}
            </div>
        </div>
    );
}

export default Categories;